// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-illustration-art-tsx": () => import("./../../../src/pages/illustration/art.tsx" /* webpackChunkName: "component---src-pages-illustration-art-tsx" */),
  "component---src-pages-illustration-index-tsx": () => import("./../../../src/pages/illustration/index.tsx" /* webpackChunkName: "component---src-pages-illustration-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-profile-tsx": () => import("./../../../src/pages/profile/profile.tsx" /* webpackChunkName: "component---src-pages-profile-profile-tsx" */),
  "component---src-pages-request-flow-tsx": () => import("./../../../src/pages/request/flow.tsx" /* webpackChunkName: "component---src-pages-request-flow-tsx" */),
  "component---src-pages-request-index-tsx": () => import("./../../../src/pages/request/index.tsx" /* webpackChunkName: "component---src-pages-request-index-tsx" */),
  "component---src-pages-request-price-tsx": () => import("./../../../src/pages/request/price.tsx" /* webpackChunkName: "component---src-pages-request-price-tsx" */),
  "component---src-pages-request-simulation-tsx": () => import("./../../../src/pages/request/simulation.tsx" /* webpackChunkName: "component---src-pages-request-simulation-tsx" */),
  "component---src-pages-request-status-tsx": () => import("./../../../src/pages/request/status.tsx" /* webpackChunkName: "component---src-pages-request-status-tsx" */),
  "component---src-pages-top-tsx": () => import("./../../../src/pages/top.tsx" /* webpackChunkName: "component---src-pages-top-tsx" */)
}

