import { createTheme } from "@material-ui/core";

const theme = createTheme({
  typography: {
    fontFamily: [
        'Helvetica Neue',
        'Arial',
        'Hiragino Kaku Gothic ProN',
        'Hiragino Sans',
        'Meiryo',
        'sans-serif'
      ].join(','),
    h2: {
      fontFamily: [
        'Amatic SC', 
        'Helvetica Neue',
        'Arial',
        'Hiragino Kaku Gothic ProN',
        'Hiragino Sans',
        'Meiryo',
        'sans-serif'
      ].join(','),
      },
    h3: {
      fontSize: '3em',
      fontFamily: [
        'Amatic SC', 
        'Helvetica Neue',
        'Arial',
        'Hiragino Kaku Gothic ProN',
        'Hiragino Sans',
        'Meiryo',
        'sans-serif'
      ].join(','),
      },
    h4: {
      fontSize: '2em',
      fontWeight: '500',
    },
    body1: {
      fontSize: '1em',
    },
    subtitle1: {
      fontWeight: '700',
    },
    subtitle2: {
      fontWeight: '700',
    }

  },
  palette: {
    primary: {
      main: '#92A4B0'
    },
    secondary: {
      main: '#E9B6A9'
    },
    info:{
      main: '#C5DF9C'
    }
  },
  overrides: {
    MuiGridListTile: {
      tile: {
        borderRadius: '10px',
      }
    },
  },
  });

export default theme;